import request from '@/utils/request.js'
 
//post请求
export const post = (url,data) => request({url:url,method:'post',data})

//get请求
export const get = (url,params) => request({url:url,method:'get',params})

// get获取小区列表(带分页)
export const getResidentialList = (page,limit,params) => request({url:'/Residential/GetResidentialList',method:'get',params:{"where.page":page,"where.limit":limit,...params}})

// get获取小区列表(不带分页) http://bdwy.dhteam.net/api/Residential/GetResidentialInfoList
export const getResidentialInfoList = (params) => request({url:'/Residential/GetResidentialInfoList',method:'get',params:{...params}})

// get获取基本信息设置列表(不带分页)   http://bdwy.dhteam.net/api/PublicData/GetMasterInfoList?where.typeCode=Department
// 传类型编码（Department，FileType，JobPosition，OwnerType，OwnerType2，UserGroupID，XiuliType）
export const getMasterInfoList = (typeCode,params) => request({url:'/PublicData/GetMasterInfoList',method:'get',params:{"where.typeCode":typeCode,...params}})

export const GetTuser = (params) => request({url:'/TUser/GetTuser',method:'get',params:{...params}})
