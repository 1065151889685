//home.js
import {login} from '@/api/user.js'
import {getResidentialList,getResidentialInfoList,getMasterInfoList,GetTuser} from '@/api/baseapi.js'

import Vue from 'vue'
import Vuex from 'vuex'
import {message} from 'element-ui';
import {title} from '@/config'
import { resetRouter } from '@/router'

Vue.use(Vuex)

const state = {
	userInfo: {}, // 登录成功后的用户信息
	userState:JSON.parse(sessionStorage.getItem('userState')) || {ut: '', isLogin: false, userType: null},// 用户状态相关 
	username:'0',
	userID: '',
	avatar:'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
	Department:[],
	FileType: [],
	JobPosition: [],
	OwnerType: [],
	OwnerType2: [],
	userGroupID: [],
	XiuliType: [],
	ResidentialInfoList: [],
	TuserList:[],
};
const getters = {
	// getUsers:(state)=> state.userInfo.msg, //获取访问该页面的用户;
	getUsers:(state)=> state.userInfo.realName, //获取访问该页面的用户;
	username: (state) => state.username,
	avatar: (state) => state.avatar,
	getUserInfo:(state)=> state.userInfo,
	getDepartment:(state)=>state.Department,
	getFileType:(state)=>state.FileType,
	getJobPosition:(state)=>state.JobPosition,
	getOwnerType:(state)=>state.OwnerType,
	getOwnerType2:(state)=>state.OwnerType2,
	getuserGroupID:(state)=>state.userGroupID,
	getXiuliType:(state)=>state.XiuliType,
	getResidentialInfoList:(state)=>state.ResidentialInfoList,
	getTuserList:(state)=>state.TuserList,
};
const mutations = {
	// 用户注销
	loginOut(state) {
		// state.userInfo = {}
		// state.username="";
		// state.avatar="";
		// state.userState ={tk:'',isLogin: false, userType: null}
		sessionStorage.clear()
	},
	// 用户登录
	setUserInfo(state, data) {
		// state.userInfo = data;
		// state.username = data.msg;
		console.log('12: ',data.result);
		state.userInfo = data.result;
		state.username = data.result.realName;
		state.userID = data.result.id;
		// 将登录的用户信息存储一份到 sessionStorage，防止刷新页面 vuex 中的用户信息清除，sessionStorage 不能存储对象，转为字符串存储
		sessionStorage.userInfo = JSON.stringify(data);
	},
	setUserState(state,data) {
	    state.userState = data;
	    sessionStorage.setItem('userState', JSON.stringify(state.userState));
	},
		// this.$store.commit('user/setMasterInfoList','Department')
	// 传类型编码（Department，FileType，JobPosition，OwnerType，OwnerType2，UserGroupID，XiuliType）
	setMasterInfoList(state,data) {
		getMasterInfoList(data).then(res => {
			if (res.success) {
				switch(data){
					case 'Department':
					state.Department=res.result
					console.log(data, state.Department);
					break;
					case 'FileType':
					state.FileType=res.result
						console.log(data, state.FileType);
					break;
					case 'JobPosition':
					state.JobPosition=res.result
					console.log(data, state.JobPosition);
					break;
					case 'OwnerType':
					state.OwnerType=res.result
					console.log(data, state.OwnerType);
					break;
					case 'OwnerType2':
					state.OwnerType2=res.result
					console.log(data, state.OwnerType2);
					break;
					case 'userGroupID':
					state.userGroupID=res.result
					console.log(data, state.userGroupID);
					break;
					case 'XiuliType':
					state.XiuliType=res.result
					console.log(data, state.XiuliType);
					break;
				
				}
				
			

			}
		})
		.catch(function(error) {
		});
	},
	setResidentialInfoList(state,id) {
		getResidentialInfoList({id:id}).then(res => {
			console.log('ResidentialInfoList: ', res);
			if (res.success) {
				state.ResidentialInfoList = res.result
				console.log('res :',res);
				console.log('res.data :',res.result);
			}
		})
		.catch(function(error) {
		});
	},
	setTuserList(state) {
		GetTuser().then(res => {
			console.log('Tuser: ', res);
			if (res.success) {
				state.TuserList = res.result
			}
		})
		.catch(function(error) {
		});
	},
};
const actions = {
	// 用户注销
	loginOut({commit}) {
		commit('loginOut');
		resetRouter();
	},
	//登录
	async login({
		commit
	}, userInfo) {
		const data = await login(userInfo);
		console.log(data);
		console.log('data.result :',data.result);
		if (data.success) {
			commit('setUserInfo', data);
			commit('setUserState', {ut:data.parameter,isLogin: true, userType:''});
			commit('setResidentialInfoList',data.result.id);
			commit('setMasterInfoList', 'Department');
			commit('setMasterInfoList', 'FileType');
			commit('setMasterInfoList', 'JobPosition');
			commit('setMasterInfoList', 'OwnerType');
			commit('setMasterInfoList', 'OwnerType2');
			commit('setMasterInfoList', 'userGroupID');
			commit('setMasterInfoList', 'XiuliType');
			commit('setTuserList');
		
			// （Department，FileType，JobPosition，OwnerType，OwnerType2，UserGroupID，XiuliType）
			const hour = new Date().getHours();
			const thisTime =hour < 8 ?'早上好' :hour <= 11 ?'上午好' :hour <= 13 ?'中午好' :hour < 18 ?'下午好' :'晚上好'
			Vue.prototype.$message.info(`欢迎登录${title},${thisTime}！`);
			return data;
		} else {
			return data;
		}
	},
};
// 注意和仓库的区别
const store = {
	// namespaced用于在全局引用此文件里的方法时标识这一个的文件名，使得让人明白这些数据来自哪个仓库
	// 即当你需要在别的文件里面使用子仓库(mapStates、mapGetters、mapActions)时，里面的方法需要注明来自哪一个模块的方法
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
export default store;
//配置插件支持