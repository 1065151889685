import request from '@/utils/request.js'
export async function login(data) {
  return request({
      url:'TUserLogin/UserLogIn',//模拟数据接口
      method: 'post',
      data
  })
}

export async function getMenu(data) {
  return request({
      url:'Menu/getMenuList.ashx',//模拟数据接口
      method: 'post',
      data
  })
}
