//home.js
import {login} from '@/api/user.js'
import Vue from 'vue'
import Vuex from 'vuex'
import {message} from 'element-ui';
import {title} from '@/config'
import {
	baseURL,
	imagehost
	
} from '@/config/index.js'

Vue.use(Vuex)

const state = {
	CurrencyList:[], 
	QuantityUnitList: [],
	PurchaseContractNoTypeList: [],
	WarehouseList:[],
	upload: process.env.NODE_ENV === 'development' ? '/newapi/ajax/FileUp.ashx?action=upFile' : imagehost +
		'ajax/FileUp.ashx?action=upFile',
};
const getters = {
	CurrencyList:(state)=> state.CurrencyList, 
	QuantityUnitList: (state) => state.QuantityUnitList,
	PurchaseContractNoTypeList: (state) => state.PurchaseContractNoTypeList,
	WarehouseList: (state) => state.WarehouseList,
	upload: (state) => state.upload,
};
const mutations = {

	setCurrencyList(state,data) {
	     state.CurrencyList =data
	},
	setQuantityUnitList(state,data) {
	     state.QuantityUnitList =data
	},
	setPurchaseContractNoTypeList(state,data) {
	     state.PurchaseContractNoTypeList =data
	},
	setWarehouseList(state,data) {
	     state.WarehouseList =data
	},
};
const actions = {
};
// 注意和仓库的区别
const store = {
	// namespaced用于在全局引用此文件里的方法时标识这一个的文件名，使得让人明白这些数据来自哪个仓库
	// 即当你需要在别的文件里面使用子仓库(mapStates、mapGetters、mapActions)时，里面的方法需要注明来自哪一个模块的方法
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
export default store;
//配置插件支持

