import Vue from 'vue'
import {
	Message,
	MessageBox,
	Notification,
	Loading
} from 'element-ui';
import i18n from "@/locale/i18n.js"
let loadingInstance;

export default {
	// 消息提示
	msg(content) {
		Message.info(content)
	},
	// 错误消息
	msgError(content) {
		Message.error(content)
	},
	// 成功消息
	msgSuccess(content) {
		Message.success(content)
	},
	// 警告消息
	msgWarning(content) {
		Message.warning(content)
	},
	// 弹出提示
	alert(content) {
		MessageBox.alert(content, i18n.t('tip'))
	},
	// 错误提示
	alertError(content) {
		MessageBox.alert(content, i18n.t('tip'), {
			type: 'error'
		})
	},
	// 成功提示
	alertSuccess(content) {
		MessageBox.alert(content, i18n.t('tip'), {
			type: 'success'
		})
	},
	// 警告提示
	alertWarning(content) {
		MessageBox.alert(content, i18n.t('tip'), {
			type: 'warning'
		})
	},
	// 通知提示
	notify(content) {
		Notification.info(content)
	},
	// 错误通知
	notifyError(content) {
		Notification.error(content);
	},
	// 成功通知
	notifySuccess(content) {
		Notification.success(content)
	},
	// 警告通知
	notifyWarning(content) {
		Notification.warning(content)
	},
	//确认窗体
	confirm(content, type, title) {
		return new Promise((resolve, reject) => {
			MessageBox.confirm(content||i18n.t('tip-confirm'), title ||i18n.t('tip'), {
				confirmButtonText:i18n.t('tip-ok'),
				cancelButtonText:i18n.t('tip-cancel') ,
				type: type || 'warning' //默认是warning 警告

			}).then(() => {

				resolve()

			}).catch(() => {



			})

		})
	},
	 // confirm(content,type) {
	 //    return MessageBox.confirm(i18n.t('tip-confirm') || content, i18n.t('tip'), {
	 //      confirmButtonText: i18n.t('tip-ok'),
	 //      cancelButtonText: i18n.t('tip-cancel'),
	 //      type:type || "warning",
	 //    })
	 //  },
	// 打开遮罩层
	loading(content) {
		console.log(content);
		loadingInstance = Loading.service({
			lock: true,
			text: content,
			spinner: "el-icon-loading",
			background: "rgba(0, 0, 0, 0.7)",
		})
	},
	// 关闭遮罩层
	closeLoading() {
		loadingInstance.close();
	}
}
