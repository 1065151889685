

import i18n from './locale/i18n'
import store from './store/index.js'//vuex
import router  from './router/index.js'//路由
import $MessageBox from './utils/Message/MessageBox.js'//消息提示
import * as API from '@/api/baseapi'
import Vue from 'vue'
import ElementUI from 'element-ui';

import { Message } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueUeditorWrap from 'vue-ueditor-wrap';
import App from './App.vue'


//定义一个新的Message方法，多传入一个offset参数
const $message = options => {
  return Message({
    ...options,
    offset: 200
  });
};
 
//重写方法,将offset写入options
['success', 'warning', 'info', 'error'].forEach(type => {
  $message[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options,
        offset: 200
      };
    }
    options.type = type;
    return Message(options);
  };
});
//将$message挂载到this上
Vue.prototype.$message = $message;
//不加这行代码运行this.$message.closeAll时会报错
Vue.prototype.$message.closeAll = Message.closeAll;

Vue.prototype.$MessageBox = $MessageBox;

Vue.config.productionTip = false

Vue.prototype.$http = API;

Vue.use(ElementUI);
Vue.component('vue-ueditor-wrap', VueUeditorWrap);

new Vue({
    el: '#app',
	store,
	router,
	i18n,
     render: h => h(App)
}).$mount('#app')

