import Vue from 'vue'
import Vuex from 'vuex'

import VuexPersistence from 'vuex-persist'

 Vue.use(Vuex)
//创建对象配置持久化
const vuexLocal = new VuexPersistence({
    //定义本地存储的方式，sessionStorage或localStorage
    storage: window.localStorage
})



const files = require.context('./modules', false, /\.js$/)
const modules = {}

files.keys().forEach((key) => {
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})
Object.keys(modules).forEach((key) => {
  modules[key]['namespaced'] = true
})
const store = new Vuex.Store({
  modules,
  plugins: [vuexLocal.plugin]
})
export default store
