import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state={
    isCollapse:false,	//侧边栏状态
	openTab: [], //所有打开的路由
	activeIndex: '/home' ,//激活状态
	fullWidth:0,
	fullHeight:0,
	
	
};
const getters={
	getisCollapse:(sate)=> {return sate.isCollapse},
	openTab:(sate)=> sate.openTab,
	fullWidth:(sate)=> sate.fullWidth,
	fullHeight:(sate)=> sate.fullHeight,
};
const mutations={
  // 改变侧边栏状态
  isCollapse_Update(state,is) {
 console.log(is);
    state.isCollapse = is
  },
// 添加tabs
	add_tabs(state, data) {
		state.openTab.push(data);
	},
	//清除tab
	clear_tabs(state){
		state.openTab=[];
	},
	clear_other(state,route){
		state.openTab.map((item,index) => {
		if (item.route !== route&&item.route !=="/home") {
			state.openTab.splice(index, 1)
		}
		})
		
	},
	// 删除tabs
	delete_tabs(state, route) {
		let index = 0;
		for (let option of state.openTab) {
			if (option.route === route) {
				break;
			}
			index++;
		}
		state.openTab.splice(index, 1);
	},
	// 设置当前激活的tab
	set_active_index(state, index) {

		state.activeIndex = index;
	},
	  setWindowsSize(state,fullWidth,fullHeight){
		  state.fullWidth =fullWidth;
		  state.fullHeight =fullHeight;
	  }
  
 };
const actions={

};
const store = {
     // namespaced用于在全局引用此文件里的方法时标识这一个的文件名，使得让人明白这些数据来自哪个仓库
     // 即当你需要在别的文件里面使用子仓库(mapStates、mapGetters、mapActions)时，里面的方法需要注明来自哪一个模块的方法
     namespaced:true,
     state,
     getters,
     mutations,
     actions
}
export default store;
