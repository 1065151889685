import request from '@/utils/request.js'

// export function getMenuList(params) {//接口
//   return request({
//       url:'api/Company/getMenuList.ashx',
//       method: 'get',
//       params
//   })
// }
// export function postMenuList(data) {//接口
//   return request({
//       url:'api/Company/getMenuList.ashx',
//       method: 'post',
//       data
//   })
// }

// export function getMenuRouter() {//获取路由菜单-zzy
//   return request({
//       url:'api/Menu/getMenuList.ashx',
//       method: 'get',
//   })
// }
export function getMenuRouter() {//获取路由菜单-rhh
  return request({
      url:'Menu/GetResidentialList',
      method: 'get',
  })
}





export function getRoleList(params) {//角色接口
  return request({
      url:'api/Company/getRoleList.ashx',
      method: 'get',
	  params
  })
}
export function postRoleList(data) {//角色接口
  return request({
      url:'api/Company/getRoleList.ashx',
      method: 'post',
	  data
  })
}

export function getAdmin_of_RoleNull () {//获取角色为空的用户
  return request({
      url:'api/Company/getAdminList.ashx',
      method: 'post',
	  data:{ action: "getAdminList", NULLRole:0 }
  })
}
export function getRoleMenu(Add_By) {//角色菜单接口
  return request({
       url:'Menu/GetResidentialList1',
      method: 'get',
	  params:{Add_By:Add_By}
  })
}

export function UpdateJurisdiction(RoleID,MenuID,btnval) {//角色菜单按钮权限
  return request({
      url:'api/Company/getJurisdiction.ashx',
      method: 'post',
	  data:{action: "UpdateJurisdiction", ADID:RoleID,MenuID:MenuID,btnval:btnval}
  })
}
export function AddJurisdictionZs(UserID,MenuID) {//角色添加菜单权限
  return request({
      url:'/Permissions/AddJurisdictionZs',
      method: 'post',
	  data:{ADID:UserID,MenuID:MenuID}
  })
}
export function DeleteJurisdiction(UserID,MenuID) {//角色添加菜单权限
  return request({
      url:'/Permissions/DeleteJurisdiction',
      method: 'post',
	  data:{ADID:UserID,MenuID:MenuID}
  })
}

//部门get接口
export function GetDepartment(params) {
  return request({
      url:'api/Company/getDepartment.ashx',
      method: 'GET',
	  params
  })
}
//部门post
export function postDepartment(data) {
  return request({
      url:'api/Company/getDepartment.ashx',
      method: 'post',
	  data
  })
}
//admin-get
export function getAdminList(params) {
  return request({
      url:'api/Company/getAdminList.ashx',
      method: 'GET',
	  params
  })
}
//admin-post
export function postAdminList(data) {
  return request({
      url:'api/Company/getAdminList.ashx',
      method: 'post',
	  data
  })
}
